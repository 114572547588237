import { SVGProps } from 'react';

const BolyNotFound = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={220}
    height={180}
    viewBox="0 0 220 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M193.818 44.2157C200.975 49.4519 206.605 56.1266 210.287 63.07C215.475 72.8507 217.644 83.4399 219.111 93.9673C223.852 127.986 210.226 167.133 163.639 177.343C132.388 184.192 98.5812 176.83 70.0783 166.283C28.1973 150.786 -30.6852 102.926 18.931 67.848C45.6035 48.9893 88.4877 56.3932 119.954 46.2049C134.694 41.4335 148.6 32.5758 165.379 33.7301C176.324 34.4769 185.984 38.4861 193.818 44.2157Z"
      fill="#30A6FF"
      fillOpacity={0.03}
    />
    <path
      opacity={0.3}
      d="M119.424 135.445C151.111 135.445 176.799 109.724 176.799 77.9945C176.799 46.2654 151.111 20.5439 119.424 20.5439C87.7362 20.5439 62.0483 46.2654 62.0483 77.9945C62.0483 109.724 87.7362 135.445 119.424 135.445Z"
      fill="url(#paint0_linear_299_4102)"
    />
    <mask
      id="mask0_299_4102"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={64}
      y={22}
      width={110}
      height={111}
    >
      <ellipse
        cx={119.239}
        cy={77.5408}
        rx={54.5595}
        ry={54.6309}
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0_299_4102)">
      <path
        d="M174.395 24.302L90.2017 22.9517H174.395V24.302Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 27.0952L65.3223 25.3462L65.3597 22.9517H90.2018L174.395 24.302V27.0952Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 29.8885L65.2781 28.1394L65.3221 25.3462L174.395 27.0953V29.8885Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 32.6817L65.2319 30.9326L65.2781 28.1394L174.395 29.8885V32.6817Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 35.4749L65.188 33.7236L65.232 30.9326L174.395 32.6817V35.4749Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 38.2681L65.144 36.5169L65.188 33.7236L174.395 35.4749V38.2681Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 41.0616L65.0977 39.3103L65.1439 36.5171L174.395 38.2684V41.0616Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 43.8568L65.0537 42.1033L65.0977 39.3101L174.395 41.0613V43.8568Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 46.6502L65.0098 44.8945L65.0538 42.1035L174.395 43.857V46.6502Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 49.4432L64.9658 47.6875L65.0098 44.8943L174.395 46.65V49.4432Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 52.2364L64.9194 50.4808L64.9656 47.6875L174.395 49.4432V52.2364Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 55.0296L64.8755 53.274L64.9195 50.4807L174.395 52.2364V55.0296Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 57.8229L64.8315 56.065L64.8755 53.2739L174.395 55.0296V57.8229Z"
        fill="#EBF6FF"
      />
      <path
        d="M174.395 60.6161L64.7852 58.8582L64.8314 56.0649L174.395 57.8228V60.6161Z"
        fill="#EAF6FF"
      />
      <path
        d="M174.395 63.4093L64.7412 61.6514L64.7852 58.8582L174.395 60.616V63.4093Z"
        fill="#EAF6FF"
      />
      <path
        d="M174.395 66.2025L64.6973 64.4446L64.7413 61.6514L174.395 63.4093V66.2025Z"
        fill="#EAF6FF"
      />
      <path
        d="M174.395 68.9957L64.6511 67.2378L64.6973 64.4446L174.395 66.2025V68.9957Z"
        fill="#EAF5FF"
      />
      <path
        d="M174.395 71.7889L64.6072 70.0288L64.6512 67.2378L174.395 68.9957V71.7889Z"
        fill="#E9F5FF"
      />
      <path
        d="M174.395 74.5824L64.563 72.8223L64.607 70.0291L174.395 71.7891V74.5824Z"
        fill="#E9F5FF"
      />
      <path
        d="M174.395 76.2519L174.377 77.3753L64.5171 75.6153L64.5633 72.822L174.395 74.5821V76.2519Z"
        fill="#E9F5FF"
      />
      <path
        d="M64.5223 75.6173L64.4775 78.4102L174.335 80.1766L174.38 77.3837L64.5223 75.6173Z"
        fill="#E8F5FF"
      />
      <path
        d="M174.331 80.1688L174.287 82.962L64.4531 81.2019V79.737L64.4729 78.4087L174.331 80.1688Z"
        fill="#E7F4FF"
      />
      <path
        d="M174.287 82.962L174.243 85.7552L64.4531 83.9951V81.2019L174.287 82.962Z"
        fill="#E7F4FF"
      />
      <path
        d="M174.243 85.7552L174.197 88.5462L64.4531 86.7883V83.9951L174.243 85.7552Z"
        fill="#E6F4FF"
      />
      <path
        d="M174.197 88.5462L174.153 91.3394L64.4531 89.5816V86.7883L174.197 88.5462Z"
        fill="#E5F3FF"
      />
      <path
        d="M174.153 91.3394L174.109 94.1327L64.4531 92.3748V89.5815L174.153 91.3394Z"
        fill="#E4F3FF"
      />
      <path
        d="M174.109 94.1326L174.063 96.9259L64.4531 95.168V92.3748L174.109 94.1326Z"
        fill="#E3F2FF"
      />
      <path
        d="M174.063 96.9259L174.019 99.7169L64.4531 97.9612V95.168L174.063 96.9259Z"
        fill="#E1F2FF"
      />
      <path
        d="M174.019 99.7169L173.975 102.51L64.4531 100.754V97.9612L174.019 99.7169Z"
        fill="#E0F1FF"
      />
      <path
        d="M173.975 102.51L173.931 105.303L64.4531 103.548V100.754L173.975 102.51Z"
        fill="#DEF1FF"
      />
      <path
        d="M173.931 105.304L173.884 108.097L64.4531 106.341V103.548L173.931 105.304Z"
        fill="#DDF0FF"
      />
      <path
        d="M173.884 108.097L173.84 110.888L64.4531 109.134V106.341L173.884 108.097Z"
        fill="#DBEFFF"
      />
      <path
        d="M173.84 110.888L173.796 113.681L64.4531 111.928V109.134L173.84 110.888Z"
        fill="#D9EEFF"
      />
      <path
        d="M173.796 113.681L173.75 116.474L64.4531 114.721V111.927L173.796 113.681Z"
        fill="#D6EDFF"
      />
      <path
        d="M173.75 116.474L173.706 119.267L64.4531 117.516V114.721L173.75 116.474Z"
        fill="#D4ECFF"
      />
      <path
        d="M173.706 119.267L173.662 122.061L64.4531 120.309V117.516L173.706 119.267Z"
        fill="#D1EBFF"
      />
      <path
        d="M173.662 122.06L173.616 124.851L64.4531 123.102V120.309L173.662 122.06Z"
        fill="#CEE9FF"
      />
      <path
        d="M173.616 124.851L173.572 127.645L64.4531 125.896V123.102L173.616 124.851Z"
        fill="#CBE8FF"
      />
      <path
        d="M173.572 127.645L173.528 130.438L64.4531 128.689V125.896L173.572 127.645Z"
        fill="#C8E7FF"
      />
      <path
        d="M173.528 130.438L173.486 133.037H161.481L64.4531 131.482V128.689L173.528 130.438Z"
        fill="#C4E5FF"
      />
      <path
        d="M64.4531 131.482L161.481 133.037H64.4531V131.482Z"
        fill="#C4E5FF"
      />
    </g>
    <path
      d="M119.466 13.9396C121.926 13.9396 123.921 11.9425 123.921 9.47886C123.921 7.01523 121.926 5.01807 119.466 5.01807C117.005 5.01807 115.011 7.01523 115.011 9.47886C115.011 11.9425 117.005 13.9396 119.466 13.9396Z"
      fill="url(#paint1_linear_299_4102)"
    />
    <path
      opacity={0.05}
      d="M119.466 18.9578C124.694 18.9578 128.932 14.714 128.932 9.4789C128.932 4.24385 124.694 0 119.466 0C114.237 0 109.999 4.24385 109.999 9.4789C109.999 14.714 114.237 18.9578 119.466 18.9578Z"
      fill="url(#paint2_linear_299_4102)"
    />
    <path
      opacity={0.18}
      d="M119.466 16.4487C123.31 16.4487 126.426 13.3282 126.426 9.47888C126.426 5.62954 123.31 2.50903 119.466 2.50903C115.621 2.50903 112.505 5.62954 112.505 9.47888C112.505 13.3282 115.621 16.4487 119.466 16.4487Z"
      fill="url(#paint3_linear_299_4102)"
    />
    <path
      opacity={0.3}
      d="M119.466 13.9396C121.926 13.9396 123.921 11.9425 123.921 9.47886C123.921 7.01523 121.926 5.01807 119.466 5.01807C117.005 5.01807 115.011 7.01523 115.011 9.47886C115.011 11.9425 117.005 13.9396 119.466 13.9396Z"
      fill="url(#paint4_linear_299_4102)"
    />
    <path
      opacity={0.1}
      d="M119.466 156.731C131.751 156.731 141.71 155.485 141.71 153.947C141.71 152.409 131.751 151.163 119.466 151.163C107.181 151.163 97.2217 152.409 97.2217 153.947C97.2217 155.485 107.181 156.731 119.466 156.731Z"
      fill="black"
    />
    <path
      d="M94.2098 72.7408C98.1707 72.7408 101.382 69.5256 101.382 65.5595C101.382 61.5934 98.1707 58.3782 94.2098 58.3782C90.2488 58.3782 87.0378 61.5934 87.0378 65.5595C87.0378 69.5256 90.2488 72.7408 94.2098 72.7408Z"
      fill="url(#paint5_linear_299_4102)"
    />
    <path
      d="M98.7594 66.2027C98.865 66.2339 98.975 66.2473 99.085 66.2424C99.2111 66.2356 99.3346 66.204 99.4486 66.1494C99.5625 66.0948 99.6646 66.0183 99.7489 65.9242C99.8333 65.8301 99.8984 65.7203 99.9403 65.601C99.9823 65.4817 100 65.3553 99.9936 65.229C99.9034 63.5064 99.3336 62.1715 98.2886 61.2639C96.7596 59.9422 94.7994 60.092 94.7158 60.0986C94.4617 60.1202 94.2266 60.242 94.0622 60.4372C93.8978 60.6324 93.8176 60.885 93.8391 61.1394C93.8607 61.3939 93.9824 61.6293 94.1773 61.7939C94.3723 61.9585 94.6245 62.0389 94.8786 62.0173C94.9138 62.0173 96.1458 61.9402 97.0412 62.731C97.6638 63.2795 98.0114 64.154 98.073 65.3282C98.083 65.5271 98.1543 65.7181 98.2773 65.8747C98.4002 66.0314 98.5687 66.146 98.7594 66.2027V66.2027Z"
      fill="white"
    />
    <path
      d="M98.7131 68.8174C99.2428 68.8174 99.6723 68.3874 99.6723 67.8569C99.6723 67.3265 99.2428 66.8965 98.7131 66.8965C98.1834 66.8965 97.7539 67.3265 97.7539 67.8569C97.7539 68.3874 98.1834 68.8174 98.7131 68.8174Z"
      fill="white"
    />
    <path
      d="M116.764 66.9227C116.764 66.9227 121.439 60.7855 127.819 67.2267"
      stroke="url(#paint6_linear_299_4102)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.36 88.8479C104.255 88.8426 104.151 88.8118 104.06 88.7581C103.968 88.7044 103.891 88.6294 103.835 88.5395C103.738 88.3733 103.701 88.1791 103.729 87.9889C103.758 87.7986 103.85 87.6238 103.991 87.4931C107.564 83.821 112.588 87.8566 112.626 87.9029C112.746 88.0563 112.806 88.2482 112.795 88.4428C112.784 88.6374 112.703 88.8214 112.566 88.9603C112.285 89.2356 111.961 89.0726 111.605 88.9052C111.464 88.8391 107.137 85.9115 104.866 88.8127C104.763 88.9426 104.499 88.8523 104.36 88.8479Z"
      fill="url(#paint7_linear_299_4102)"
      stroke="url(#paint8_linear_299_4102)"
      strokeWidth={0.43}
      strokeMiterlimit={10}
    />
    <path
      d="M88.0412 94.5026C88.3206 95.8133 89.3612 96.8156 90.3798 96.7385C94.496 96.4279 97.2658 97.1019 98.4559 99.807C99.0015 101.047 100.172 101.699 101.07 101.265C101.967 100.831 102.251 99.4766 101.703 98.2364C99.1335 92.3988 93.9614 91.6455 89.37 91.9913C88.358 92.0794 87.764 93.1941 88.0412 94.5026Z"
      fill="url(#paint9_linear_299_4102)"
    />
    <path
      opacity={0.35}
      d="M80.724 85.6671C87.6788 85.6671 93.3167 80.0217 93.3167 73.0579C93.3167 66.094 87.6788 60.4487 80.724 60.4487C73.7693 60.4487 68.1313 66.094 68.1313 73.0579C68.1313 80.0217 73.7693 85.6671 80.724 85.6671Z"
      fill="#F2F2F2"
    />
    <path
      d="M67.3306 74.1812C67.3306 74.1812 69.5877 79.4856 74.8523 82.2855C78.5395 84.2438 83.089 85.5655 88.0874 84.5897C88.0874 84.5897 72.3025 91.3855 67.3306 74.1812Z"
      fill="#BDC7CC"
    />
    <path
      d="M95.7476 109.262L92.6456 110.363C92.6066 110.378 92.5651 110.386 92.5233 110.385C92.4815 110.383 92.4403 110.374 92.4022 110.357C92.3641 110.34 92.3299 110.315 92.3014 110.284C92.2729 110.254 92.2508 110.218 92.2364 110.178L84.0393 85.5107L86.6793 84.5723L95.9566 108.848C95.9716 108.889 95.978 108.932 95.9755 108.976C95.973 109.019 95.9616 109.061 95.942 109.1C95.9224 109.139 95.895 109.174 95.8616 109.201C95.8281 109.229 95.7893 109.25 95.7476 109.262Z"
      fill="#2E4865"
    />
    <path
      d="M95.7476 109.262L92.6456 110.363C92.6066 110.378 92.5651 110.385 92.5233 110.384C92.4815 110.383 92.4403 110.374 92.4022 110.357C92.3641 110.339 92.3299 110.315 92.3014 110.284C92.2729 110.253 92.2508 110.217 92.2364 110.178L84.0393 85.5104L86.6793 84.572L95.9566 108.848C95.9716 108.888 95.978 108.932 95.9755 108.975C95.973 109.019 95.9616 109.061 95.942 109.1C95.9224 109.139 95.895 109.173 95.8616 109.201C95.8281 109.229 95.7893 109.25 95.7476 109.262Z"
      fill="#67768C"
    />
    <path
      d="M95.3581 108.746L92.3662 109.848C92.3284 109.863 92.288 109.87 92.2475 109.868C92.2069 109.867 92.167 109.858 92.1301 109.841C92.0932 109.824 92.06 109.8 92.0325 109.77C92.0051 109.741 91.9839 109.705 91.9702 109.667L84.0745 85.5106L86.6022 84.5898L95.5495 108.352C95.5762 108.43 95.5711 108.515 95.5352 108.589C95.4993 108.663 95.4357 108.719 95.3581 108.746V108.746Z"
      fill="#5B697C"
    />
    <path
      d="M76.9444 59.8363C75.112 60.3616 73.4089 61.2631 71.9435 62.4833C70.478 63.7035 69.2822 65.2158 68.4321 66.9239C67.5821 68.6321 67.0964 70.4987 67.0059 72.405C66.9155 74.3113 67.2223 76.2156 67.9068 77.9967C70.4412 84.6053 77.7187 88.2356 84.5035 86.2772C86.3358 85.7519 88.0389 84.8505 89.5044 83.6302C90.9698 82.41 92.1657 80.8977 93.0157 79.1896C93.8658 77.4815 94.3514 75.6149 94.4419 73.7086C94.5323 71.8023 94.2255 69.898 93.541 68.1169C91.0066 61.5171 83.7291 57.878 76.9444 59.8363ZM85.3989 84.7639C83.0352 85.7083 80.4413 85.9135 77.9589 85.3524C75.4765 84.7912 73.2221 83.4902 71.4928 81.6206C69.7636 79.7511 68.6406 77.4008 68.2719 74.8795C67.9032 72.3581 68.3061 69.7841 69.4275 67.4966C70.5488 65.2091 72.3361 63.3155 74.5536 62.0653C76.7711 60.8151 79.3149 60.2671 81.8498 60.4933C84.3846 60.7195 86.7915 61.7095 88.7532 63.3327C90.715 64.9559 92.1396 67.1361 92.8392 69.5862C93.6968 72.5895 93.4106 75.8046 92.036 78.6087C90.6614 81.4128 88.2963 83.6062 85.3989 84.7639V84.7639Z"
      fill="#2E4865"
    />
    <path
      opacity={0.75}
      d="M78.8429 62.0919C81.6039 61.7527 86.2678 61.9245 88.9364 66.154C88.9877 66.2371 89.0085 66.3356 88.9952 66.4324C88.9819 66.5293 88.9353 66.6185 88.8634 66.6847C88.7916 66.7509 88.699 66.79 88.6015 66.7952C88.504 66.8005 88.4077 66.7716 88.3292 66.7135C86.457 65.3279 82.9986 63.2021 78.8451 62.6646C78.7753 62.6562 78.711 62.6226 78.6643 62.57C78.6175 62.5175 78.5916 62.4496 78.5913 62.3792C78.591 62.3089 78.6165 62.2408 78.6628 62.1879C78.7091 62.135 78.7732 62.1008 78.8429 62.0919V62.0919Z"
      fill="white"
    />
    <path
      d="M101.932 98.9548C102.689 102.517 98.4118 106.458 93.8205 106.804C90.6107 107.046 86.2019 104.768 85.3197 100.614C84.8225 98.2785 84.9325 95.4478 91.4665 95.0491C98.821 94.6019 101.27 95.8377 101.932 98.9548Z"
      fill="url(#paint10_linear_299_4102)"
    />
    <path
      d="M63.0031 88.1145C63.4541 89.4803 63.1549 90.9782 62.3431 91.4519C58.9947 93.4014 57.1996 95.5183 57.655 98.6684C57.864 100.113 57.292 101.349 56.379 101.433C55.466 101.517 54.5596 100.415 54.3396 98.968C53.3584 92.1678 56.9796 88.6741 60.6909 86.5153C61.5203 86.0372 62.5521 86.7598 63.0031 88.1145Z"
      fill="url(#paint11_linear_299_4102)"
    />
    <path
      d="M79.6106 76.5603C79.6106 76.5603 78.2797 73.1767 75.8597 73.397C73.4397 73.6173 51.4531 76.5603 51.4531 76.5603C51.4531 76.5603 54.0117 77.1727 54.9731 78.8557L65.1194 108.779L89.0046 106.784L79.6106 76.5603Z"
      fill="#83CAFF"
    />
    <path
      d="M51.1407 76.5602C51.1407 76.5602 48.6966 77.4965 50.5468 80.6686L74.3065 77.4964C74.3065 77.4964 73.4815 73.8155 75.3493 73.4146L51.1407 76.5602Z"
      fill="#D6EDFF"
    />
    <path
      d="M54.5331 99.6705C55.7585 103.378 61.0737 104.715 64.7894 102.558C67.3898 101.049 69.6294 96.5556 68.2104 92.2292C67.4052 89.795 65.8806 87.2309 60.6337 90.3942C54.7245 93.954 53.4596 96.4234 54.5331 99.6705Z"
      fill="url(#paint12_linear_299_4102)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_299_4102"
        x1={135.025}
        y1={22.7927}
        x2={103.743}
        y2={133.342}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EBF6FF" />
        <stop offset={0.46} stopColor="#E9F5FF" />
        <stop offset={0.69} stopColor="#E1F2FF" />
        <stop offset={0.87} stopColor="#D4ECFF" />
        <stop offset={1} stopColor="#C4E5FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_299_4102"
        x1={121.765}
        y1={5.56972}
        x2={117.175}
        y2={13.2194}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#62F4C0" />
        <stop offset={0.25} stopColor="#4FD7D7" />
        <stop offset={0.54} stopColor="#3EBCED" />
        <stop offset={0.8} stopColor="#34ACFA" />
        <stop offset={1} stopColor="#30A6FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_299_4102"
        x1={-153657}
        y1={-16645.9}
        x2={-154473}
        y2={-15233.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#62F4C0" />
        <stop offset={0.25} stopColor="#4FD7D7" />
        <stop offset={0.54} stopColor="#3EBCED" />
        <stop offset={0.8} stopColor="#34ACFA" />
        <stop offset={1} stopColor="#30A6FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_299_4102"
        x1={-113032}
        y1={-12099.7}
        x2={-113473}
        y2={-11336.1}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#62F4C0" />
        <stop offset={0.25} stopColor="#4FD7D7" />
        <stop offset={0.54} stopColor="#3EBCED" />
        <stop offset={0.8} stopColor="#34ACFA" />
        <stop offset={1} stopColor="#30A6FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_299_4102"
        x1={121.765}
        y1={5.56972}
        x2={117.175}
        y2={13.2194}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#62F4C0" />
        <stop offset={0.25} stopColor="#4FD7D7" />
        <stop offset={0.54} stopColor="#3EBCED" />
        <stop offset={0.8} stopColor="#34ACFA" />
        <stop offset={1} stopColor="#30A6FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_299_4102"
        x1={87.0586}
        y1={64.8597}
        x2={101.337}
        y2={66.2553}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2E4865" stopOpacity={0.8} />
        <stop offset={1} stopColor="#2E4865" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_299_4102"
        x1={27558.8}
        y1={4105.67}
        x2={28221.1}
        y2={4192.41}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2E4865" stopOpacity={0.8} />
        <stop offset={1} stopColor="#2E4865" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_299_4102"
        x1={5041.59}
        y1={1143.57}
        x2={5415.4}
        y2={1158.51}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2E4865" stopOpacity={0.8} />
        <stop offset={1} stopColor="#2E4865" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_299_4102"
        x1={4347.5}
        y1={1555.86}
        x2={4677.3}
        y2={1689.74}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2E4865" stopOpacity={0.8} />
        <stop offset={1} stopColor="#2E4865" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_299_4102"
        x1={105.227}
        y1={87.4255}
        x2={92.8938}
        y2={89.9848}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#355673" />
        <stop offset={0.42} stopColor="#345470" />
        <stop offset={0.67} stopColor="#304D68" />
        <stop offset={0.88} stopColor="#29415A" />
        <stop offset={1} stopColor="#23364D" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_299_4102"
        x1={-59878.8}
        y1={138030}
        x2={-59878.8}
        y2={139550}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#355673" />
        <stop offset={0.42} stopColor="#345470" />
        <stop offset={0.67} stopColor="#304D68" />
        <stop offset={0.88} stopColor="#29415A" />
        <stop offset={1} stopColor="#23364D" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_299_4102"
        x1={35813.8}
        y1={-10926.7}
        x2={36578.9}
        y2={-11184.9}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#355673" />
        <stop offset={0.42} stopColor="#345470" />
        <stop offset={0.67} stopColor="#304D68" />
        <stop offset={0.88} stopColor="#29415A" />
        <stop offset={1} stopColor="#23364D" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_299_4102"
        x1={36704.9}
        y1={-5144.25}
        x2={37681}
        y2={-5473.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#355673" />
        <stop offset={0.42} stopColor="#345470" />
        <stop offset={0.67} stopColor="#304D68" />
        <stop offset={0.88} stopColor="#29415A" />
        <stop offset={1} stopColor="#23364D" />
      </linearGradient>
    </defs>
  </svg>
);

export default BolyNotFound;
